import React from 'react';
import styled from 'styled-components';
import constant from '../constants.js';

const Container = styled.div`
    margin-left: auto;
    margin-right: auto;
    max-width: 80em;
    box-sizing: border-box;
    padding-left: 1px;
    padding-right: 1px;
`;

const List = styled.ul`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0;
    margin-bottom: 0.5em;
    padding: 0;

    @media (max-width: ${constant.size.mobile}) {
        flex-direction: column;
    }
`;

const Item = styled.li`
    display: block;
    margin-left: 0.5em;
    margin-right: 0.5em;

    .small {
        font-size: 0.75em;
    }

    @media (max-width: ${constant.size.mobile}) {
        margin-bottom: 0.5em;
    }
`;

function Footer(props) {
    const {
        onUsc2257Click,
        onPrivacyClick,
        onTermsClick,
        contactEmail,
        onLogoutClick,
        isLoggedIn,
    } = props;
    const mailTo = `mailto:${contactEmail}`;
    return (
        <footer>
            <Container>
                <List>
                    <Item>© 2023</Item>
                    <Item className="secondary-text">
                        <a href="https://team18cash.com">Webmasters</a>
                    </Item>
                    <Item className="secondary-text">
                        <a href="/privacy" onClick={onPrivacyClick}>
                            Privacy
                        </a>
                    </Item>
                    <Item className="secondary-text">
                        <a href="/terms" onClick={onTermsClick}>
                            Terms
                        </a>
                    </Item>
                    <Item className="secondary-text">
                        <a href={mailTo}>Contact Us</a>
                    </Item>
                    {isLoggedIn && (
                        <Item className="secondary-text">
                            <a onClick={onLogoutClick}>Logout</a>
                        </Item>
                    )}
                </List>
                <List>
                    <Item className="secondary-text">
                        <a href="/usc2257" onClick={onUsc2257Click}>
                            18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement
                        </a>
                    </Item>
                </List>
                <List>
                    <Item>
                        <span className="small">
                            <b>Billing:</b> For inquiries or to cancel your membership, please visit
                            the payment company on your statement:{` `}
                        </span>
                        <a className="secondary-text" href="https://www.epoch.com/">
                            EPOCH
                        </a>
                        <span className="small"> or </span>
                        <a className="secondary-text" href="https://www.vtsup.com/">
                            VEROTEL
                        </a>
                    </Item>
                </List>
            </Container>
        </footer>
    );
}

export default Footer;
